(function ($, window, document) {
    let pluginName = 'convertFeaturedToFilm',
        defaults = {};


		function Features(element, options){
      this.element = $(element);
      this.converted = false;
      this.recovered = true;
      this.clientWidth = $(window).width();
      this.options = this.options = $.extend({}, defaults, options);
      this.init();
    }

    Features.prototype.init = function(){

      this.applyConvert();

      $(window).on('resize.features', ()=>{ 
        this.clientWidth = $(window).width();
        this.applyConvert() 
      })
      
    }

    Features.prototype.applyConvert = function(){
      if (this.clientWidth <= 1023 && this.recovered) {
        this.convertFeatures();
      } else if (this.clientWidth >= 1024 && this.converted) {
        this.recoverFeatures();
      }
    }

    Features.prototype.convertFeatures = function(){
      let $el = $(this.element),
          $slideArea = $el.find('.right_container_flip'),
          $slide = $slideArea.find('.item');

      $el.addClass('ss-slider');
      $slideArea.addClass('ss-slider-scroll-area msnt-items-slider_slides');
      $slide.addClass('ss-item msnt-items-slider_slide');

      $el.parent('.wrapper_filmstrip').addClass('slider-ready');

      $($el).snapScrollControll();
      
      this.converted = true;
      this.recovered = false;
    }

    Features.prototype.recoverFeatures = function(){
      let $el = $(this.element),
          $slideArea = $el.find('.right_container_flip'),
          $slide = $slideArea.find('.item');

      let isSnapScroll = $el.data('snapScroll');

      if (isSnapScroll) isSnapScroll.destroy();

      setTimeout(() => {
        $slide.removeClass('ss-item msnt-items-slider_slide')
        $el.removeClass('ss-slider');
        $slideArea.removeClass('ss-slider-scroll-area msnt-items-slider_slides');
      }, 100);

      this.converted = false;
      this.recovered = true;
    }

  
  $.fn[pluginName] = function ( options ) {
    return this.each(function () {
        if (!$.data(this, 'convertFeatures')) {
            $.data(this, 'convertFeatures',
            new Features( this, options ));
        }
    });
  }

}(jQuery, window, document))