import { vr, currentPage, initNewSection } from '../common/layout';
import {
  banners,
  commentsCountUpdate,
  hitPageView,
  checkAndFilterCoralCommentsAmountToShow,
} from '../common/utils/fn';
import { getLoadMoreContent } from '../common/utils/load-more-btn-new.-loader';
import 'jquery-scrolltie';
import {
  getM1FindVehicleEu,
  getM1FindVehicleUs,
  getM1FindVehicleByType,
} from '../common/shop/m1-find-vehicle-widget-loader';
import '../common/right-featured-to-film';
// import '../common/jquery.sticky';
import { getNewsletterWidget } from '../common/widgets/widget-newsletter-loader';

// import { showIEVRedirectPopup } from '../common/index-popup';
import { getCoralCommentsCountUpdate } from '../common/utils/coral-requests-loader';
// import '../common/lazyload.picture';
import '../common/jquery-snap-scroll';
import '../common/jquery.convertmosaic';

currentPage.pageInit = ($page, pageData, pageId) => {
  let loadMoreButton = document.querySelector('.m1-pager--next .m1-button');
  loadMoreButton?.addEventListener('click', (event) => {
    event.preventDefault();
    getLoadMoreContent().then((api) => {
      api.loadMoreButtonNew(loadMoreButton);
    });
  });

  // initLoadMoreBtn();
  // bigImgLazyload();

  // $('.right_container_flip_wrapper').convertFeaturedToFilm();
  $('.msnt-items-slider').snapScrollControl();
  // $('.latestAndFeatures .section-sidebar').stickySidebar({
  //   containerSelector: '.latestAndFeatures',
  // });
  // $(".sidebar").stickySidebar({containerSelector: ".content-area"});

  //disqus authorization. close auth window
  let currentURL = window.location.href;
  var url = new URL(currentURL);
  var searchLoginM1 = url.searchParams.get('is_comment_login');
  var searchLoginFacebook = url.searchParams.get('nc');
  // if ?is_comment_login=1 window should be closed at all
  if (searchLoginM1) {
    if (searchLoginM1 == '1') {
      window.close();
    }
  }
  // else if (searchLoginFacebook) {
  //   if (searchLoginFacebook == '1') {
  //     window.close();
  //   }
  // }

  // $(window).on('resize load', function(event) {
  //   /* Act on the event */
  //   var windowWidth = document.documentElement.clientWidth;
  //   if (windowWidth < 1024) {
  //     $('.wrapper_filmstrip')
  //       .eq(0)
  //       .css({
  //         display: 'none',
  //       });
  //     $('.featuredBox').not('.small-info-box-ad')
  //       .eq(0)
  //       .css({
  //         display: 'none',
  //       });
  //   } else {
  //     $('.wrapper_filmstrip')
  //       .eq(0)
  //       .css({
  //         display: '',
  //       });
  //     $('.featuredBox')
  //       .eq(0)
  //       .css({
  //         display: '',
  //       });
  //   }
  // });

  commentsCountUpdate();
  getCoralCommentsCountUpdate().then((api) => {
    api.coralCommentsCountUpdate();
  });

  // if (currentEdition == 'ev_us') {
  //   showIEVRedirectPopup();
  // }

  getM1FindVehicleEu().then((api) => {
    api.m1FindVehicleEu();
  });
  getM1FindVehicleUs().then((api) => {
    api.m1FindVehicleUs();
  });
  getM1FindVehicleByType().then((api) => {
    api.m1FindVehicleByType();
  });
  // $('#main_CR_mobile').convertMosaicToFilm();

  // var $img = $('.reload img.m1-cse-item_image');

  // if ($img.length > 0) {
  //   var qLenght = $img.size() - 1;

  //   $(window).on('load', function() {
  //     $img.each(function(i, q) {
  //       if (i === qLenght && $img[qLenght].complete) {
  //         $('.reload .msnt-items-slider_slide').css('height', '100%');
  //         return false;
  //       }
  //     });
  //   });
  // }

  getNewsletterWidget().then((api) => {
    api.newsletter();
  });

  checkAndFilterCoralCommentsAmountToShow();

  // if ($(window).width() > 767) {
  //   return { dotdotdotSelectors: '.m1-top-slider-items .ss-item .middle' };
  // }
};

// function forceReloadJS(srcUrlContains) {
//   // $('.coral-count').empty();
//   // $('.coral-count').removeAttr('data-coral-ref');
//   // $('.coral-count').removeAttr('data-coral-count');
//   $.each($('script:empty[src*="' + srcUrlContains + '"]'), function(index, el) {
//     var oldSrc = $(el).attr('src');
//     var t = +new Date();
//     var newSrc = oldSrc + '?' + t;

//     console.log(oldSrc, ' to ', newSrc);

//     $(el).remove();
//     $('<script/>').attr('src', newSrc).appendTo('head');
//   });
// }

currentPage.pageLoadMore = ($html, $btnBox) => {
  let currentEdition = document.querySelector('body').dataset.edtn;
  // $btnBox.replaceWith($html);
  initNewSection($html);

  let loadMoreButton = document.querySelector('.m1-pager--next .m1-button');
  loadMoreButton?.addEventListener('click', (event) => {
    event.preventDefault();
    getLoadMoreContent().then((api) => {
      api.loadMoreButtonNew(loadMoreButton);
    });
  });

  // hybridLazyLoad();
  getM1FindVehicleEu().then((api) => {
    api.m1FindVehicleEu();
  });
  getM1FindVehicleUs().then((api) => {
    api.m1FindVehicleUs();
  });
  getCoralCommentsCountUpdate().then((api) => {
    api.coralCommentsCountUpdate();
  });
  banners($html);
  // bannerLazyload();
  commentsCountUpdate(true);
  hitPageView(document.location.pathname);
  checkAndFilterCoralCommentsAmountToShow();
  // if ($(window).width() > 767) {
  //   return { dotdotdotSelectors: '.latestAndFeatures .section-centerbar .text-box' };
  // }
};
