export const getM1FindVehicleEu = () => {
  let result;
  if (result) return Promise.resolve(result);
  return import('./m1-find-vehicle-eu').then((module) => {
    result = module;
    return module;
  });
};

export const getM1FindVehicleUs = () => {
  let result;
  if (result) return Promise.resolve(result);
  return import('./m1-find-vehicle-us').then((module) => {
    result = module;
    return module;
  });
};

export const getM1FindVehicleByType = () => {
  let result;
  if (result) return Promise.resolve(result);
  return import('./m1-find-vehicle-by-type').then((module) => {
    result = module;
    return module;
  });
};
