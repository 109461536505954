let uniqueEventId = 0;

(function ($, window, document) {
  let pluginName = 'snapScrollControl',
    defaults = {
      onload: undefined,
      afterDestroy: undefined,
      duration: 300,
      animation: 'swing',
    };

  function SnapScrollControl(container, options) {
    this.$container = $(container);
    this.$slider = $(container).find('.ss-slider');
    this.$controls = this.$container.find('.ss-controls');
    this.$buttonNext = this.$controls.find('.button-next');
    this.$buttonPrev = this.$controls.find('.button-prev');
    this.direction = $('body').data('rtl');
    this.$scrollArea = this.$slider.find('.ss-slider-scroll-area');
    this.$slide = this.$slider.find('.ss-item').first();
    this.scrolledEndFinish = true;
    this.iSiOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    this.index = 0;

    this.options = $.extend({}, defaults, options);
    this._defaults = defaults;
    this._name = pluginName;
    this.uniqueEventId = uniqueEventId++;
    this.init();
  }

  SnapScrollControl.prototype.init = function () {
    if (typeof this.options.onload === 'function') {
      this.options.onload();
    }

    this.sliderWidth = this.$slider.outerWidth();
    this.slideWidth = Math.round(this.$slide.outerWidth(true));
    this.scrollLeft = this.$scrollArea.scrollLeft();
    this.scrollAreaWidth = this.$scrollArea.get(0).scrollWidth;

    this.count = this.$scrollArea.children().length - Math.round(this.sliderWidth / this.slideWidth);

    this.setuponeventclick();
    this.addremovecontrols();

    this.$scrollArea.on('scroll.snapScroll', this.scroll.bind(this));
    this.$scrollArea.on('scrollend.snapScroll', this.scrollend.bind(this));

    $(window).on(
      `resize.snapScroll${this.uniqueEventId}`,
      this.resize.bind(this),
    );
  };

  SnapScrollControl.prototype.fadeinoutbutton = function () {
    if (this.$controls.length === 0) return;

    if (this.index == 0) {
      this.$buttonPrev.css({
        opacity: 0.5,
        cursor: 'auto',
      });
      this.$buttonNext.css({
        opacity: 1,
        cursor: 'pointer',
      });
    } else if (this.index == this.count) {
      this.$buttonPrev.css({
        opacity: 1,
        cursor: 'pointer',
      });
      this.$buttonNext.css({
        opacity: 0.5,
        cursor: 'auto',
      });
    } else {
      this.$buttonNext.css({
        opacity: 1,
        cursor: 'pointer',
      });
      this.$buttonPrev.css({
        opacity: 1,
        cursor: 'pointer',
      });
    }
  };

  SnapScrollControl.prototype.setuponeventclick = function () {
    this.$buttonPrev.on('click.snapScroll', () => {
      if (this.index == 0 || !this.scrolledEndFinish) return;
      this.swipe('backward', this.direction);
    });
    this.$buttonNext.on('click.snapScroll', () => {
      if (this.index == this.count || !this.scrolledEndFinish) return;
      this.swipe('forward', this.direction);
    });
  };

  SnapScrollControl.prototype.addremovecontrols = function () {
    let sliderWidth = this.$slider.outerWidth(true),
      scrollWidth = this.scrollAreaWidth,
      isVisible = this.$controls.is(':visible');

    if (
      this.$controls.length === 0 &&
      scrollWidth > sliderWidth &&
      !isVisible
    ) {
      this.$controls.css('display', 'block');
    } else if (scrollWidth <= sliderWidth && isVisible) {
      this.$controls.css('display', 'none');
    }

    this.fadeinoutbutton();
  };

  SnapScrollControl.prototype.scroll = function () {
    let $slide = this.$slide;
    this.scrolledEndFinish = false;

    clearTimeout($.data(this, 'snapScrollTimeout'));
    $.data(
      this,
      'snapScrollTimeout',
      setTimeout(function () {
        $slide.trigger('scrollend');
      }, 100),
    );
  };

  SnapScrollControl.prototype.scrollend = function () {
    this.scrollLeft = this.$scrollArea.scrollLeft();

    $(window).trigger('scroll');

    this.fadeinoutbutton();

    this.scrolledEndFinish = true;
  };

  SnapScrollControl.prototype.resize = function () {
    if (this.$scrollArea.length === 0) return;

    this.sliderWidth = this.$slider.outerWidth();
    this.slideWidth = Math.round(this.$slide.outerWidth(true));
    this.scrollLeft = this.$scrollArea.scrollLeft();
    this.scrollAreaWidth = this.$scrollArea.get(0).scrollWidth;

    this.addremovecontrols();

    this.$scrollArea.scrollLeft(0);

    this.index = 0;
    this.count = this.$scrollArea.children().length - Math.round(this.sliderWidth / this.slideWidth);
  };

  SnapScrollControl.prototype.swipe = function (direction, globalDirection) {
    direction === 'backward' ? this.index-- : this.index++;
    const destination = Math.round(this.slideWidth * this.index) * (globalDirection ? -1 : 1);

    this.scrollLeft = destination;

    if (this.iSiOS) {
      this.$scrollArea.animate(
        { scrollLeft: destination },
        this.options.duration,
        this.options.animation,
      );
    } else {
      this.$scrollArea.scrollLeft(destination);
    }

  };

  SnapScrollControl.prototype.destroy = function () {
    $(window).off(`resize.snapScroll${this.uniqueEventId}`);
    this.$slider.removeClass('.ss-slider');
    this.$scrollArea.removeClass('.ss-slider-scroll-area');

    this.destroed = true;

    this.$slider.data('snapScroll', false);

    if (typeof this.options.afterDestroy === 'function') {
      this.options.afterDestroy();
    }
  };

  $.fn[pluginName] = function (options) {
    return this.each(function () {
      if (!$.data(this, 'snapScroll')) {
        $.data(this, 'snapScroll', new SnapScrollControl(this, options));
      }
    });
  };
})(jQuery, window, document);
