import device from 'current-device';

(function ($, window, document) {
  let pluginName = 'convertMosaicToFilm',
      defaults = {};


  function Mosaic(element, options){
    this.element = $(element);
    this.converted = false;
    this.recovered = true;
    this.clientWidth = $(window).width();
    this.options = this.options = $.extend({}, defaults, options);
    this.init();
  }

  Mosaic.prototype.init = function(){
    this.applyConvert();

    $(window).on('resize.features', ()=>{ 
      this.clientWidth = $(window).width();
      this.applyConvert() 
    })
  }

  Mosaic.prototype.applyConvert = function(){
    if (this.clientWidth <= 1023 && this.recovered) {
      this.convertMosaic();
    } else if (this.clientWidth >= 1024 && this.converted) {
      this.recoverMosaic();
    }
  }

  Mosaic.prototype.convertMosaic = function(){
    let $el = $(this.element),
        $slider = $el.find('.content-area-box'),
        $slideArea = $el.find('.m1-items-mosaic'), 
        $slide = $slideArea.find('.item'),
        $sliderTitleWrapper = device.type === 'mobile' ? $el.find('#container_head_mobile') : $el.find('#container_head'),
        $sliderTitle = device.type === 'mobile'? $sliderTitleWrapper.find('#wrapper_head_mobile'): $sliderTitleWrapper.find('#wrapper_head');

        $el.addClass('msnt-items-slider');
        $slider.addClass('ss-slider');
        $sliderTitleWrapper.removeClass('m1-center').addClass('msnt-items-slider_header');
        $sliderTitle.removeClass('m1-block-head').addClass('msnt-items-slider_header-start');
        $slideArea.addClass('msnt-items-slider_slides_CR ss-slider-scroll-area msnt-items-slider_slides').removeClass('browseBox');
        $slide.addClass('msnt-items-slider_slide ss-item msnt-items-slider_slide');
        $el.addClass('msnt-visible-slider');


    $($slider).snapScrollControll();
    
    this.converted = true;
    this.recovered = false;
  }

  Mosaic.prototype.recoverMosaic = function(){
    let $el = $(this.element),
        $slider = $el.find('.content-area-box'),
        $slideArea = $el.find('.m1-items-mosaic'), 
        $slide = $slideArea.find('.item'),
        $sliderTitleWrapper = device.type === 'mobile' ? $el.find('#container_head_mobile') : $el.find('#container_head'),
        $sliderTitle = device.type === 'mobile'? $sliderTitleWrapper.find('#wrapper_head_mobile'): $sliderTitleWrapper.find('#wrapper_head');

    let isSnapScroll = $slider.data('snapScroll');

    if (isSnapScroll) isSnapScroll.destroy();

    setTimeout(() => {

      $el.removeClass('msnt-items-slider');
      $slider.removeClass('ss-slider');
      $slideArea.addClass('browseBox').removeClass('msnt-items-slider_slides_CR ss-slider-scroll-area msnt-items-slider_slides');
      $slide.removeClass('msnt-items-slider_slide ss-item msnt-items-slider_slide');
      $sliderTitleWrapper.removeClass('msnt-items-slider_header').addClass('m1-center');
      $sliderTitle.removeClass('msnt-items-slider_header-start').addClass('m1-block-head');
    }, 100);

    this.converted = false;
    this.recovered = true;
  }


$.fn[pluginName] = function ( options ) {
  return this.each(function () {
      if (!$.data(this, 'convertMosaicToFilm')) {
          $.data(this, 'convertMosaicToFilm',
          new Mosaic( this, options ));
      }
  });
}

}(jQuery, window, document))